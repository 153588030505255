import { MouseEvent, Suspense, useEffect, useState } from "react";
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { RootState, store } from "@store/store";
// import { CustomModal } from "@components/CustomModal";
// import { setShowValidateFileModal } from "@store/slices/appSlice";
// import { RejectApplicationDialogSegment } from "@components/RejectApplicationDialogSegment";

import LogoLendia from "@assets/lendia_full.svg";
// import finbe from "@assets/companies/finbe.svg";
import AccountIcon from "@assets/account_icon.svg";
// import { RejectDocumentDialogSegment } from "@components/RejectDocumentDialogSegment";
import { createSelector } from "@reduxjs/toolkit";
import { DialogManager } from "@components/DialogManager";
import { PopupManager } from "@components/PopupManager";

export const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // const [childrenModal, setChildrenModal] = useState<ReactNode | null>();
  // const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  // const modalState = useSelector((state: RootState) => state.app.components.modal);

  const companiesUser = useSelector((state: RootState) => state.app.session.companies);

  const permissions = (state = store.getState()) => state.app.session.permissions;
  const sessionPermissions = createSelector([permissions], (permissions) => {
    return permissions?.filter((permission) => ["MREZ", "MLNE"].includes(permission.module.code));
  });

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    dispatch({ type: "CLEAN_STORE" });
    await signOut();
    navigate("/login");
  };

  // const handleOnRejectApplicationClicked = () => {
  //   setChildrenModal(
  //     <RejectApplicationDialogSegment
  //       onBackClick={handleOnModalBackClicked}
  //       onConfirmClick={handleOnModalConfirmClicked}
  //     />,
  //   );
  //   setShowConfirmModal(true);
  // };

  // const handleOnModalBackClicked = () => {
  //   setShowConfirmModal(false);
  // };

  // const handleOnModalConfirmClicked = () => {
  //   setShowConfirmModal(false);
  // };

  useEffect(() => {
    if (["/login", "/cambiar_contrasenia", "/recuperar_cuenta"].some((item) => item === location.pathname) === false) {
      fetchAuthSession().then((value) => {
        if (value.tokens === undefined) {
          navigate("/login");
        }
      });
    }
  }, []);

  return (
    <>
      <Box height={"100vh"}>
        <AppBar position="static" sx={{ bgcolor: "white", boxShadow: 0 }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              {companiesUser.length > 0 ? (
                <Box
                  component="img"
                  src={`/src/assets/companies/${companiesUser.at(0)?.name.toLowerCase()}.svg`}
                  alt={companiesUser.at(0)?.name}
                />
              ) : (
                <Box component="img" src={LogoLendia} alt="logo" sx={{ height: 25 }} />
              )}
            </Box>
            <Box ml={4} mr={1}>
              <IconButton onClick={handleMenu}>
                <img src={AccountIcon} width={"22px"} height={"22px"} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                onClose={handleClose}
                open={Boolean(anchorEl)}
              >
                <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
              </Menu>
            </Box>
            {companiesUser.length > 0 && <Box component="img" src={LogoLendia} alt={"Lendia"} />}
          </Toolbar>
        </AppBar>
        <Box mx={2} height={"93vh"}>
          <Suspense fallback={<></>}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>

      {/* <CustomModal open={showConfirmModal} children={childrenModal} /> */}
      {/* <CustomModal
        open={modalState.shown}
        children={
          modalState.modal === "RejectDocument" && (
            <RejectDocumentDialogSegment
              document={undefined}
              isProcessing={false}
              onBackClick={modalState.onActionOne}
              onConfirmClick={modalState.onActionTwo}
            />
          )
        }
      /> */}
      <DialogManager />
      <PopupManager />
    </>
  );
};
