import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3: React.CSSProperties;
  }

  interface Palette {
    main1: Palette["primary"];
    main2: Palette["primary"];
    main3: Palette["primary"];
    main4: Palette["primary"];
    main5: Palette["primary"];
    secondary1: Palette["primary"];
    secondary2: Palette["primary"];
    secondary3: Palette["primary"];
    secondary4: Palette["primary"];
    secondary5: Palette["primary"];
    secondary6: Palette["primary"];
  }

  interface PaletteOptions {
    main1: PaletteOptions["primary"];
    main2: PaletteOptions["primary"];
    main3: PaletteOptions["primary"];
    main4: PaletteOptions["primary"];
    main5: PaletteOptions["primary"];
    secondary1: PaletteOptions["primary"];
    secondary2: PaletteOptions["primary"];
    secondary3: PaletteOptions["primary"];
    secondary4: PaletteOptions["primary"];
    secondary5: PaletteOptions["primary"];
    secondary6: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    default: true;
    blue_outlined: true;
  }

  interface ButtonPropsColorOverrides {
    main1: true;
    main2: true;
    main3: true;
    main4: true;
    main5: true;
    secondary1: true;
    secondary2: true;
    secondary3: true;
    secondary4: true;
    secondary5: true;
    secondary6: true;
    secondary7: true;
    secondary8: true;
  }
}

let theme = createTheme({
  typography: {
    fontFamily: ["Outfit"].join(","),
    h1: {
      fontSize: 39,
      fontWeight: 300,
    },
    h2: {
      fontSize: 31,
      fontWeight: 300,
    },
    h3: {
      fontSize: 25,
      fontWeight: 300,
    },
    h4: {
      fontSize: 20,
      fontWeight: 300,
    },
    h5: {
      fontSize: 16,
      fontWeight: 300,
    },
    body1: {
      fontSize: 16,
      fontWeight: 300,
    },
    body2: {
      fontSize: 13,
      fontWeight: 300,
    },
    body3: {
      fontSize: 11,
      fontWeight: 300,
      fontFamily: "Outfit",
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#A3D4E8",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A3D4E8",
          },
          "& .MuiInput-underline::before": {
            borderColor: "#A3D4E8",
          },
          "& .MuiFilledInput-underline::before": {
            borderColor: "#A3D4E8",
          },
          "& .MuiInputLabel-asterisk": {
            color: "#FF6060",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#FF6060",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-asterisk": {
            color: "#FF6060",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          // "& .MuiSelect-icon": {
          //   color: "#A3D4E8",
          // },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#A3D4E8",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          ":disabled": {
            cursor: "not-allowed",
            opacity: 0.4,
            pointerEvents: "auto",
          },
        },
      },
    },
  },
});

theme = createTheme(theme, {
  palette: {
    main1: theme.palette.augmentColor({
      color: {
        main: "#A3D4E8",
      },
      name: "main1",
    }),
    main2: theme.palette.augmentColor({
      color: {
        main: "#528CD6",
      },
      name: "main2",
    }),
    main3: theme.palette.augmentColor({
      color: {
        main: "#002652",
      },
      name: "main3",
    }),
    main4: theme.palette.augmentColor({
      color: {
        main: "#F2704F",
        contrastText: "#fff",
      },
      name: "main4",
    }),
    main5: theme.palette.augmentColor({
      color: {
        main: "#BB3545",
      },
      name: "main5",
    }),
    secondary1: theme.palette.augmentColor({
      color: {
        main: "#2C2C2C",
      },
      name: "secondary1",
    }),
    secondary2: theme.palette.augmentColor({
      color: {
        main: "#818181",
      },
      name: "secondary2",
    }),
    secondary3: theme.palette.augmentColor({
      color: {
        main: "#E35050",
      },
      name: "secondary3",
    }),
    secondary4: theme.palette.augmentColor({
      color: {
        main: "#01A388",
      },
      name: "secondary4",
    }),
    secondary5: theme.palette.augmentColor({
      color: {
        main: "#E3F2F8",
      },
      name: "secondary5",
    }),
    secondary6: theme.palette.augmentColor({
      color: {
        main: "#FFFFFF",
      },
      name: "secondary6",
    }),
    secondary7: theme.palette.augmentColor({
      color: {
        main: "#894DD8",
      },
      name: "secondary7",
    }),
    secondary8: theme.palette.augmentColor({
      color: {
        main: "#613797",
      },
      name: "secondary8",
    }),
  },
});

export const BackOfficeTheme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: (t: any) => {
          let color = theme.palette[t.ownerState.color].contrastText;
          if (t.classes?.root.includes("MuiButton-loadingPositionCenter")) {
            color = "transparent";
          }

          return {
            "&.MuiButton-root": {
              borderRadius: 6,
              textTransform: "none",
              fontSize: 16,
              fontWeight: 700,
              lineHeight: "22px",
            },
            "&.Mui-disabled": {
              backgroundColor: theme.palette[t.ownerState.color].main,
              color: color,
              opacity: 0.4,
              boxShadow: "none",
            },
            "& .MuiButton-loadingIndicator": {
              color: theme.palette[t.ownerState.color].contrastText,
            },
          };
        },
      },
      variants: [
        {
          props: {
            variant: "blue_outlined",
          },
          style: {
            color: "#002652",
            backgroundColor: "#FFF",
            border: "2px solid #002652",
            lineHeight: "22px",
            "&.Mui-disabled": {
              color: "#bdbdbd",
              border: "2px solid #bdbdbd",
              backgroundColor: "transparent",
            },
            "&.MuiButton-blue_outlinedSizeSmall": {
              fontSize: 11,
              fontWeight: 600,
              padding: "2px 8px",
            },
          },
        },
      ],
    },
  },
});
